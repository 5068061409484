{
  const controller = new ScrollMagic.Controller();

  const headerScroll = () => {
    const titleTrigger = 0.17;
    const translateDuration = 650;
    const translateValue = 255;

    new ScrollMagic.Scene({
      triggerElement: '#hometop',
      triggerHook: titleTrigger,
      duration: translateDuration,
    })
      .setTween('.homepage__titleblock', {
        css: {
          transform: `translateY(${translateValue}px)`,
        },
      })
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '#hometop',
      triggerHook: titleTrigger,
      duration: translateDuration,
    })
      .setTween('.homepage__services__list', {
        css: {
          transform: `translateY(${translateValue}px)`,
        },
      })
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '#hometop',
      triggerHook: 0.17,
      duration: 150,
    })
      .setTween('.homepage__services', {
        css: {
          opacity: 0,
        },
      })
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '#hometop',
      triggerHook: 0.4,
      duration: 1000,
    })
      .setTween('.homepage__projects__item', {
        css: {
          transform: 'scale(1.8)',
        },
      })
      .addTo(controller);
  };

  const checkScreensize = () => {
    if (window.innerWidth > 900) {
      headerScroll(controller);
    }
  };

  init = () => {
    checkScreensize();
    // headerScroll(controller);
  };

  init();
}
