import './_menu.js';
import './_scrollMagic.js';
import './_cookieBanner'

{
  const init = () => {
    // document.getElementById('year').innerHTML = new Date().getFullYear();
  };

  init();
}
