{

  handleToggleMenu = (e) => {
    const navActive = document.querySelector(".nav--active")

    if (!navActive) {
      e.currentTarget.innerHTML = "close";

      document.getElementById("pagecontent").classList.add("hidebody");

      document.getElementById("nav").classList.remove("hidden");
  
      setTimeout(() => {
        document.getElementById("nav").classList.add("nav--active");
      }, 200);
  
      
  
      setTimeout(() => {
        document.getElementById("pagecontent").classList.add("hidden");
      }, 300); 

    } else {
      e.currentTarget.innerHTML = "menu";

      document.getElementById("pagecontent").classList.remove("hidden");

      document.getElementById("nav").classList.remove("nav--active");

      setTimeout(() => {
        document.getElementById("pagecontent").classList.remove("hidebody");

        document.getElementById("nav").classList.add("hidden");
      }, 300);

      
  
      // setTimeout(() => {
      //   document.getElementById("nav").classList.remove("nav--active");
      // }, 200);
  
      
  
      // // setTimeout(() => {
      // //   document.getElementById("pagecontent").classList.toggle("hidden");
      // // }, 300); 
    }
  }

  const init = () => {
    document.getElementById("menubutton").addEventListener(`click`, handleToggleMenu)
  };

  init();
}
